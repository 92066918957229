import React from "react";
import "./Nav.css";

export default function Nav() {
    return (
        <nav className="home-nav">
            <a href="">Social Media</a>
            <a href="">Streaming Music</a>
        </nav>
    );
}