import React from "react";
import Nav from "../Nav/Nav.jsx";
import "./Home.css";

export default function Home() {
    return (
        <div className="home">
            <h1>U-Turn AKA BossHotBox</h1>
            <nav>
                <div className="nav-social">
                    <h2>Social Media</h2>
                    <a href="https://www.snapchat.com/add/bosshotboxx">Snapchat</a>
                    <a href="https://twitter.com/one8ghty">Twitter</a>
                    <a href="https://www.facebook.com/one8ghty">Facebook</a>
                    <a href="https://www.instagram.com/one8ghty/">Instagram</a>
                </div>

                <div className="nav-music">
                    <h2>Streaming Music</h2>
                    <a href="https://soundcloud.com/one8ghty">Soundcloud</a>
                    <a href="https://www.reverbnation.com/one8ghty">Reverbnation</a>
                    <a href="https://open.spotify.com/artist/3bX80s0uBrhZllZSvYdX05?si=yEvyoyEwSCuvc7w2M5g4PA&utm_source=copy-link&dl_branch=1">Spotify</a>
                    <a href="https://www.youtube.com/channel/UCjZo71TkRousK40JKuuSRRA">Youtube</a>
                </div>

            </nav>
        </div>
    );
}